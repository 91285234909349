import React, { useCallback, useEffect, useMemo } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grow,
    makeStyles,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/hooks";
import { useQueryParam } from "use-query-params";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import lottie from "lottie-web";
import lodash from "lodash";

const useStyles = makeStyles({
    logo: {
        width: 128,
        height: 128,
    },
});

export const Home: React.FC = observer(() => {
    const [next, setNext] = useQueryParam("next");

    const auth = useAuth();
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const styles = useStyles();

    const createRoom = useCallback(() => {
        if (auth.user && !auth.user.anonymous) {
            history.push("/room");
        } else {
            setNext("create-room");
            auth.signIn().then(null);
        }
    }, [auth, history, setNext]);

    useEffect(() => {
        if (next === "create-room") {
            createRoom();
            setNext(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const animateLogo = useMemo(
        () =>
            lodash.once(container => {
                lottie.destroy("logo-anim");
                const anim = lottie.loadAnimation({
                    name: "logo-anim",
                    path: "/anim/icon-anim.json",
                    loop: false,
                    container,
                });
                anim.setSpeed(0.7);
            }),
        []
    );

    return (
        <Box
            bgcolor={theme.palette.primary.main}
            color={theme.palette.primary.contrastText}>
            <Container>
                <Box
                    p={5}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}>
                    <Box
                        p={2}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}>
                        <Grow appear in timeout={1000}>
                            <Box
                                ref={r => r && animateLogo(r)}
                                className={styles.logo}
                                id="logo-anim"
                            />
                        </Grow>
                    </Box>

                    <Box
                        p={3}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}>
                        <Grow appear in timeout={1500}>
                            <Typography
                                variant={"h4"}
                                fontWeight={"600"}
                                component={"span"}
                                textAlign={"center"}
                                color={theme.palette.primary.contrastText}>
                                {t("general.APP_NAME")}
                            </Typography>
                        </Grow>
                        <Grow appear in timeout={2000}>
                            <Typography
                                variant={"h6"}
                                fontWeight={"300"}
                                textAlign={"center"}
                                color={theme.palette.primary.contrastText}>
                                {t("general.APP_DESC")}
                            </Typography>
                        </Grow>
                    </Box>

                    <Box
                        p={3}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}>
                        <Grow appear in timeout={2500}>
                            <Button
                                variant={"outlined"}
                                color={"inherit"}
                                onClick={createRoom}>
                                <Box display={"flex"} alignItems={"center"}>
                                    {t("app.CREATE_ROOM")}

                                    {auth.loading && (
                                        <Box pl={2} lineHeight={0}>
                                            <CircularProgress
                                                color={"inherit"}
                                                size={18}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Button>
                        </Grow>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
});
