export enum PokerType {
    FIBONACCI_SHORT = 0,
    FIBONACCI_SHORT_W_ZERO = 1,
    FIBONACCI_LONG = 2,
    FIBONACCI_LONG_W_ZERO = 3,
}

export interface Room {
    code: string;
    creatorUid: string;
    creatorParticipates: boolean;
    participants: Record<string, string>;
    pokerType: PokerType;
    tasks: Record<string, Task>;
    dirty: boolean;
}

export interface Task {
    name: string

    mediationStart: string;
    mediationStartT: number;
    mediationStartTR: number;

    votes: Record<string, TaskPoint>;
    votesFlipped: boolean;
    chosenPoints: TaskPoint;
}

export type TaskPoint = string | "COFFEE" | "UNKNOWN";

export interface UseDataReturn<T> {
    data: T | null;
    loading: boolean;
}
