import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pt from "./_pt.json";
import en from "./_en.json";

const resources = {
    en: { translation: en },
    pt: { translation: pt },
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        keySeparator: ".",
        interpolation: { escapeValue: false },
    })
    .then(null);

export default i18n;
