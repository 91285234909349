import React, { useCallback, useMemo, useState } from "react";
import { Alert, Snackbar } from "@material-ui/core";
import { Color } from "@material-ui/core/Alert";
import { ToastContextValue } from "./types";

export const ToastContext = React.createContext<ToastContextValue | null>(null);

export const ToastProvider: React.FC = ({children}) => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState<Color>("info");
    const [message, setMessage] = useState<string | null>(null);
    const close = useCallback(() => setOpen(false), []);

    const value = useMemo<ToastContextValue>(() => ({
        show(message: string, color = "info") {
            setTimeout(() => {
                setOpen(false);

                setTimeout(() => {
                    setColor(color);
                    setMessage(message);
                    setOpen(true);
                }, 200);
            }, 100);
        }
    }), []);

    return (
        <ToastContext.Provider value={value}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={close}
                      anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                <Alert onClose={close} severity={color}>
                    {message}
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    )
}