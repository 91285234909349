import React, {useMemo, useState} from "react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Grow,
  Typography,
} from "@material-ui/core";
import {useStyles} from "../styles";
import {PokerCardProps} from "./types";
import {Coffee} from "@material-ui/icons";
import {useAuth} from "../../../auth/hooks";
import classNames from "classnames";
import {updateRoom} from "../util";
import lodash from "lodash";
import {useTranslation} from "react-i18next";

import "./index.css";

export const PokerCard: React.FC<PokerCardProps> = ({
                                                      loading,
                                                      timeout,
                                                      cards,
                                                      room,
                                                      task,
                                                      taskUID,
                                                      participants,
                                                      tada,
                                                    }) => {
  const [loadingVote, setLoadingVote] = useState(
    {} as Record<string, string>
  );

  const {t} = useTranslation();
  const styles = useStyles({});
  const auth = useAuth();

  const vote = useMemo(() => {
    if (task && auth.user) {
      return task.votes[auth.user.uid];
    }

    return null;
  }, [auth, task]);

  const votesPercent = useMemo(() => {
    const hasAllVotes = participants.every(p => !!(task?.votes || {})[p]) || task?.votesFlipped;

    if (hasAllVotes) {
      const votes = task?.votes || {};
      const total = Object.values(votes).length;
      const percents = {} as Record<
        string,
        { percent: number; count: number }
      >;

      Object.values(votes).forEach(v => {
        if (typeof percents[v] === "undefined") {
          percents[v] = {
            count: 0,
            percent: 0,
          };
        }
        percents[v].percent += 1;
        percents[v].count += 1;
      });

      Object.keys(percents).forEach(vote => {
        percents[vote].percent /= total;
      });

      return percents;
    }

    return {};
  }, [participants, task?.votes, task?.votesFlipped]);

  const addVote = useMemo(() => {
    return lodash.debounce(async vote => {
      setLoadingVote(current => ({...current, [vote]: true}));
      const data = room.data;
      if (data) {
        updateRoom(data.code, current => {
          if (taskUID && auth.user) {
            return {
              dirty: true,
              tasks: {
                ...current.tasks,
                [taskUID]: {
                  ...current.tasks[taskUID],
                  votes: {
                    ...current.tasks[taskUID].votes,
                    [auth.user.uid]: vote,
                  },
                },
              },
            };
          }

          return current;
        }).finally(() => {
          setLoadingVote(current => ({...current, [vote]: false}));
        });
      }
    }, 400);
  }, [auth.user, room.data, taskUID]);

  return (
    <Grow in={!loading} timeout={timeout}>
      <Box className={styles.pokerCards}>
        {cards.map((c, i) => (
          <Grow appear in key={String(i)}>
            <div className={
              (tada && c === vote) ? "poker-card" : "poker-card poker-card-nothing"
            }>
              <Box
                className={classNames(styles.pokerCardWrapper, {
                  [styles.pokerCardWrapperSelected]:
                  c === vote,
                  [styles.pokerCardWrapperNotSelected]:
                  c !== vote,
                })}>
                <ButtonBase
                  classes={{root: styles.pokerCard}}
                  onClick={() => addVote(c)}>
                  {c === "C" ? (
                    <Coffee style={{fontSize: 72}}/>
                  ) : c === "0.5" ? (
                    <Typography
                      variant={"h4"}
                      fontSize={48}
                      fontWeight={900}>
                      1/2
                    </Typography>
                  ) : (
                    <Typography
                      variant={"h4"}
                      fontSize={72}
                      fontWeight={900}>
                      {c}
                    </Typography>
                  )}

                  {!!loadingVote[c] && (
                    <Box className={styles.fitLoading}>
                      <CircularProgress size={48}/>
                    </Box>
                  )}

                  <Grow appear in={!!votesPercent[c]} timeout={!!votesPercent[c] ? 600 : 20}>
                    <Box
                      className={classNames(
                        styles.column,
                        styles.percent
                      )}>
                      <Typography
                        fontWeight={"600"}
                        variant={"body1"}>
                        {votesPercent[c]?.count || 0}{" "}
                        {t(
                          (votesPercent[c]?.count || 0) === 1
                            ? "app.VOTE"
                            : "app.VOTES"
                        )}
                      </Typography>
                      <Typography
                        fontWeight={"400"}
                        variant={"body2"}>
                        {Math.round(
                          (votesPercent[c]?.percent || 0) *
                          100
                        )}
                        %
                      </Typography>
                    </Box>
                  </Grow>
                </ButtonBase>
              </Box>
            </div>
          </Grow>
        ))}
      </Box>
    </Grow>
  );
};
