import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import "./index.css"

const GIFS = [
  "https://giphy.com/embed/MXM5QQ3jY7WmcmPwTI",
  "https://giphy.com/embed/G96zgIcQn1L2xpmdxi",
  "https://giphy.com/embed/wqbAfFwjU8laXMWZ09",
  "https://giphy.com/embed/StKiS6x698JAl9d6cx",
  "https://giphy.com/embed/IwAZ6dvvvaTtdI8SD5",
  "https://giphy.com/embed/blSTtZehjAZ8I",
]

const COLORS = ["red", "blue", "green", "yellow"];

export const Tada: React.FC<{ onFinish?: () => void }> = ({onFinish}) => {
  useEffect(() => {
    const t = setTimeout(() => {
      onFinish && onFinish();
    }, 10000);
    return () => clearTimeout(t);
  }, [])

  const [gif] = useState(() => GIFS[Math.floor(Math.random() * GIFS.length)]);
  const [papers] = useState(() => new Array(500).fill(null).map((_, index) => ({
    color: COLORS[Math.floor(Math.random() * COLORS.length)],
    angle: Math.random() * 60 - 30,
    size: Math.random() * 48 + 12,
    timing: `animation-timing-function: cubic-bezier(${[
      Math.random() * 0.6 + 0.6,
      Math.random() * 0.6 + 0.6,
      Math.random() * 0.6 + 0.6,
      Math.random() * 0.6 + 0.6,
    ].join(",")})`,
  })));

  return createPortal(
    <div className="tada" style={{animationDelay: `${2000}ms`}}>
      <iframe src={gif} width="240" height="240"
              frameBorder={0}
              className="giphy-embed" allowFullScreen></iframe>
      {papers.map(({color, angle, size, timing}, index) => (
        <div key={index} className="paper-wrapper"
             style={{transform: `rotate(${angle.toFixed(2)}deg)`}}>
          <div className="paper"
               style={{
                 left: `${Math.random() * 100}%`,
                 animation: `tada-paper-anim-${index % 2 + 1}`,
                 animationDuration: `${Math.random() * 4000 + 2000}ms`,
                 animationDelay: `${Math.random() * 4000 + 2000}ms`,
                 animationTimingFunction: timing,
                 width: size,
                 height: size,
               }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="-2015 -2000 4030 4030">
              <title>maple leaf from the flag of Canada</title>
              <path fill={color}
                    d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"/>
            </svg>
          </div>
        </div>
      ))}
    </div>,
    document.body
  );
}