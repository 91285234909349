import React, { useCallback, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Container,
    makeStyles,
    Popover,
    Toolbar,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/hooks";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { Person } from "@material-ui/icons";

const useStyles = makeStyles({
    icon: {
        width: 42,
        height: 42,
    },
    iconWrapper: {
        lineHeight: 0,
        cursor: "pointer",
    },
    avatar: {
        width: 36,
        height: 36,
        borderRadius: 18,
        overflow: "hidden",
    },
    title: {
        cursor: "pointer",
    },
});

export const Header: React.FC = observer(() => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const styles = useStyles();
    const history = useHistory();
    const auth = useAuth();
    const theme = useTheme();
    const { t } = useTranslation();

    const openMenu = useCallback(e => {
        setMenuAnchor(e.target as HTMLElement);
    }, []);

    const closeMenu = useCallback(() => {
        setMenuAnchor(null);
    }, []);

    const signIn = useCallback(() => {
        auth.signIn().then(null);
    }, [auth]);

    const signOut = useCallback(() => {
        closeMenu();
        auth.signOut().then(null);
    }, [auth, closeMenu]);

    const goHome = useCallback(() => {
        history.push("/");
    }, [history]);

    return (
        <AppBar position="static" color={"inherit"} elevation={2}>
            <Toolbar disableGutters>
                <Container>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        ml={2}
                        mr={2}>
                        <Box
                            className={styles.iconWrapper}
                            mr={2}
                            alignItems={"center"}
                            onClick={goHome}>
                            <img
                                className={styles.icon}
                                src={"/img/icon192.png"}
                                alt={t("general.APP_NAME")}
                            />
                        </Box>

                        <Typography
                            className={styles.title}
                            variant="h6"
                            color={theme.palette.primary.main}
                            onClick={goHome}>
                            {t("general.APP_NAME")}
                        </Typography>

                        <Box flex={1} />

                        {auth.loading && <CircularProgress size={"24px"} />}

                        {(!auth.user || auth.user.anonymous) && (
                            <Button
                                onClick={signIn}
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}>
                                {t("auth.SIGN_IN")}
                            </Button>
                        )}

                        <Box>
                            {!!auth.user && !auth.user.anonymous && (
                                <Box className={styles.avatar}>
                                    <ButtonBase
                                        className={styles.avatar}
                                        onClick={openMenu}
                                        title={auth.user?.name || "-"}>
                                        {auth.user.photo ? (
                                            <img
                                                alt={auth.user?.name || "-"}
                                                className={styles.avatar}
                                                src={auth.user?.photo || ""}
                                            />
                                        ) : (
                                            <Box className={styles.avatar}>
                                                <Person fontSize={"large"} />
                                            </Box>
                                        )}
                                    </ButtonBase>
                                </Box>
                            )}

                            <Popover
                                open={!!menuAnchor}
                                anchorEl={menuAnchor}
                                onClose={closeMenu}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}>
                                <Box p={1}>
                                    <Button onClick={signOut}>
                                        {t("auth.SIGN_OUT")}
                                    </Button>
                                </Box>
                            </Popover>
                        </Box>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    );
});
