import {useContext} from "react";
import {ToastContext} from "./index";
import {ToastContextValue} from "./types";

export const useToast = (): ToastContextValue => {
    const toast = useContext(ToastContext)

    if (!toast) {
        throw new Error("Can't find toast context.")
    }

    return toast
}