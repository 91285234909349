import React, { useCallback, useMemo } from "react";
import {
    Box,
    Grow,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { RoomParticipant } from "../../../components/RoomParticipant";
import { useStyles } from "../styles";
import { useTranslation } from "react-i18next";
import { TeamCardProps } from "./types";
import { ContentCopy } from "@material-ui/icons";
import { useToast } from "../../../components/Toast/hooks";

export const TeamCard: React.FC<TeamCardProps> = ({
    participants,
    creatorUID,
    timeout,
    loading,
    votes,
    votesFlipped,
    onRemoved,
}) => {
    const styles = useStyles({});
    const { t } = useTranslation();
    const toast = useToast();

    const select = useCallback(() => {
        const input = document.getElementById(
            "tp-link-input"
        ) as HTMLInputElement;
        input?.select();
        input?.setSelectionRange(0, 99999); /* For mobile devices */
    }, []);

    const copyToClipboard = useCallback(() => {
        select();
        document.execCommand("copy");
        toast.show(t("app.TEAM_LINK_COPIED"));
    }, [t, toast, select]);

    const hasAllVotes = useMemo(() => {
        return participants.every(p => !!votes[p]) || votesFlipped;
    }, [participants, votes, votesFlipped]);

    return (
        <Grow in={!loading} timeout={timeout}>
            <Paper className={styles.participantsListPaper}>
                <Box className={styles.column} height={"100%"}>
                    <Box p={1} className={styles.column}>
                        <Box p={1} className={styles.column}>
                            <Typography variant={"h6"} fontWeight={"600"}>
                                {t("app.TEAM")}
                            </Typography>
                            <Typography variant={"body2"}>
                                {t("app.TEAM_LINK")}
                            </Typography>
                        </Box>

                        <Box display={"flex"} alignItems={"center"}>
                            <Box p={1} flex={1}>
                                <TextField
                                    id={"tp-link-input"}
                                    color={"primary"}
                                    size={"small"}
                                    value={`${window.location.href}`}
                                    contentEditable={false}
                                    onClick={select}
                                    fullWidth
                                />
                            </Box>

                            <Box p={1}>
                                <IconButton
                                    color={"primary"}
                                    onClick={copyToClipboard}>
                                    <ContentCopy />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        className={classNames(
                            styles.column,
                            styles.participantsListScroll
                        )}>
                        {participants.map((uid, i) => (
                            <RoomParticipant
                                key={uid}
                                userUid={uid}
                                moderator={uid === creatorUID}
                                moderatorUid={creatorUID}
                                vote={votes[uid] || null}
                                votesFlipped={votesFlipped}
                                showVote={hasAllVotes}
                                index={i}
                                onRemoved={
                                    uid === creatorUID ? undefined : onRemoved
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Grow>
    );
};
