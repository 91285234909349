import { useContext } from "react";
import { AuthContextValue } from "./types";
import { AuthContext } from "./index";

export const useAuth = (): AuthContextValue => {
    const auth = useContext(AuthContext);

    if (!auth) {
        throw new Error(`Can't find auth context.`);
    }

    return auth;
};
