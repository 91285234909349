import {Room, UseDataReturn} from "./types";
import {useEffect, useMemo, useState} from "react";
import {User} from "../auth/types";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {child, DataSnapshot, getDatabase, onValue, ref} from "firebase/database";

const Firestore = getFirestore();
const Database = getDatabase();

export const useRoom = (
  code: string | null | undefined
): UseDataReturn<Room> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Room | null>(null);
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    if (code) {
      return onSnapshot(doc(Firestore, "rooms", code), snapshot => {
        if (snapshot.exists()) {
          setData(snapshot.data() as Room);
          setUid(snapshot.id);
        } else {
          setData(null);
          setUid(null);
        }
        setLoading(false);
      });
    }
  }, [code]);

  return useMemo(() => ({loading, data, uid}), [data, loading, uid]);
};

export const useParticipant = (
  uid: string
): UseDataReturn<{ user: User | null; online: boolean }> => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    user: User | null;
    online: boolean;
  } | null>(null);

  useEffect(() => {
    return onSnapshot(doc(Firestore, "users", uid), snapshot => {
      if (snapshot.exists()) {
        setData(current => ({
          user: snapshot.data() as any,
          online: current ? current.online : false,
        }));
      } else {
        setData(current => ({
          user: null,
          online: current ? current.online : false,
        }));
      }
      setLoading(false);
    });
  }, [uid]);

  useEffect(() => {
    const sessionRef = child(ref(Database, "sessions"), uid);
    const onChange = (snapshot: DataSnapshot) => {
      if (snapshot.exists()) {
        setData(current => ({
          user: current?.user || null,
          online: true,
        }));
      } else {
        setData(current => ({
          user: current?.user || null,
          online: false,
        }));
      }
      setLoading(false);
    };
    const unbind = onValue(sessionRef, onChange);
    return () => unbind();
  }, [uid]);

  return {
    data,
    loading,
  };
};
