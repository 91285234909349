import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { AuthProvider } from "./auth";
import "./i18n";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { ThemeLight } from "./theme";
import { Header } from "./components/Header";
import { ToastProvider } from "./components/Toast";
import { QueryParamProvider } from "use-query-params";
import { Room } from "./pages/Room";

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}

const BrowserRouter_ = BrowserRouter as any;

const App: React.FC = () => (
    <MuiThemeProvider theme={ThemeLight}>
        <CssBaseline />
        <ToastProvider>
            <BrowserRouter_>
                <QueryParamProvider ReactRouterRoute={Route as any}>
                    <AuthProvider>
                        <Header />

                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route exact path={["/room", "/room/:code"]}>
                                <Room />
                            </Route>
                            <Route path="*">
                                Redirect
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </AuthProvider>
                </QueryParamProvider>
            </BrowserRouter_>
        </ToastProvider>
    </MuiThemeProvider>
);

export default App;
