import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grow,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router";
import {PokerType, Room as IRoom} from "../../database/types";
import {useAuth} from "../../auth/hooks";
import {useRoom} from "../../database/hooks";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {useToast} from "../../components/Toast/hooks";
import {createRoom, updateRoom} from "./util";
import lodash from "lodash";
import {TeamCard} from "./TeamCard";
import {PokerCard} from "./PokerCard";
import {HashID} from "../../util/HashID";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {Tada} from "../../components/Tada";

const Firestore = getFirestore();

const DEF_POKER_TYPES = {
  [PokerType.FIBONACCI_SHORT]: {
    title: "app.POKER_FIBONACCI_SHORT",
    cards: ["1", "2", "3", "5", "8", "13", "C", "?"],
  },
  [PokerType.FIBONACCI_SHORT_W_ZERO]: {
    title: "app.POKER_FIBONACCI_SHORT_W_ZERO",
    cards: ["0", "1", "2", "3", "5", "8", "13", "C", "?"],
  },
  [PokerType.FIBONACCI_LONG]: {
    title: "app.POKER_FIBONACCI_LONG",
    cards: ["1", "2", "3", "5", "8", "13", "21", "34", "C", "?"],
  },
  [PokerType.FIBONACCI_LONG_W_ZERO]: {
    title: "app.POKER_FIBONACCI_LONG_W_ZERO",
    cards: ["0", "1", "2", "3", "5", "8", "13", "21", "34", "C", "?"],
  },
};


export const Room: React.FC = () => {
  const {code} = useParams<{ code?: string }>();
  const decodedCode = code ? HashID.decode(code) : code;

  const [taskUid, setTaskUid] = useState<string | null>(null);
  const [taskName, setTaskName] = useState("");
  const [taskNameDirty, setTaskNameDirty] = useState(false);
  const [taskParticipate, setTaskParticipate] = useState(false);
  const [taskParticipateDirty, setTaskParticipateDirty] = useState(false);
  const [taskPokerType, setTaskPokerType] = useState(PokerType.FIBONACCI_LONG);
  const [taskPokerTypeDirty, setTaskPokerTypeDirty] = useState(false);

  const [tada, setTada] = useState(false);

  const auth = useAuth();
  const toast = useToast();
  const history = useHistory();
  const {t} = useTranslation();
  const styles = useStyles({});

  const room = useRoom(decodedCode);
  const isOwner = useMemo(() => {
    return auth.user && room.data?.creatorUid === auth.user?.uid;
  }, [auth.user, room.data?.creatorUid]);
  const task = useMemo(
    () => (room.data && taskUid ? room.data.tasks[taskUid] : null),
    [room.data, taskUid]
  );
  const participants = useMemo(() => {
    const _parts = room.data
      ? Object.entries(room.data.participants)
        .sort(([, a], [, b]) => a.localeCompare(b))
        .map(([uid]) => uid)
      : [];

    if (room.data?.creatorParticipates) {
      _parts.splice(0, 0, room.data.creatorUid);
    }

    return _parts;
  }, [room.data]);

  const timeouts = useMemo(
    () => ({
      value: 0,
    }),
    []
  );

  const getTimeout = useCallback(
    (reset?: boolean) => {
      if (reset) {
        timeouts.value = 100;
      } else {
        timeouts.value += 200;
      }

      return timeouts.value;
    },
    [timeouts]
  );

  const backToHome = useCallback(() => {
    history.push("/");
  }, [history]);

  const reset = useCallback(() => {
    if (decodedCode && taskUid) {
      updateRoom(decodedCode, current => ({
        ...current,
        tasks: {
          ...current.tasks,
          [taskUid]: {
            ...current.tasks[taskUid],
            votes: {},
            votesFlipped: false
          }
        }
      })).finally(() => {
        toast.show(t("app.VOTING_RESTARTED"))
      });
    }
  }, [decodedCode, t, taskUid, toast]);

  const flipVotes = useCallback(() => {
    if (decodedCode && taskUid) {
      updateRoom(decodedCode, current => ({
        ...current,
        tasks: {
          ...current.tasks,
          [taskUid]: {
            ...current.tasks[taskUid],
            votesFlipped: true
          }
        }
      })).finally(() => {
        toast.show(t("app.CARDS_FLIPPED"))
      });
    }
  }, [decodedCode, t, taskUid, toast]);

  const remove = useCallback(userUid => {
    if (decodedCode) {
      updateRoom(decodedCode, current => {
        const ps = current.participants;
        delete ps[userUid];
        return {
          ...current,
          participants: ps,
        };
      }).finally(() => {
        toast.show(t("app.PARTICIPANT_REMOVED"))
      });
    }
  }, [decodedCode, t, toast]);

  /**
   * Load room from URL code.
   * */
  useEffect(() => {
    (async () => {
      try {
        /**
         * Starting a new room.
         * */
        if (decodedCode) {
          /**
           * Check if the code is valid.
           * */
          const room = await getDoc(doc(Firestore, "rooms", decodedCode));

          if (room.exists()) {
            const roomData = room.data() as IRoom;
            /**
             * If signed, and the user isn't the creator,
             * just register the user as a participant.
             * */

            await updateRoom(decodedCode, (data) => {
              if (auth.user) {
                if (auth.user.uid !== roomData.creatorUid) {
                  return {
                    participants: {
                      ...data.participants,
                      [auth.user.uid]: auth.user.name || "",
                    },
                  }
                }
              }

              return data;
            });


            /**
             * Updating current task.
             * */
            const lastTaskUid = Object.entries(roomData.tasks).sort(
              (a, b) =>
                a[1].mediationStartTR - b[1].mediationStartTR
            )[0][0];
            setTaskUid(lastTaskUid);

            console.log(" --- Setting last taskUid", lastTaskUid);
          } else {
            backToHome();
            toast.show(t("app.INVALID_ROOM"), "error");
          }
        } else {
          /**
           * Whether signed, create the room.
           * */
          if (auth.user) {
            const roomCode = await createRoom(auth.user);

            if (roomCode) {
              history.push(`/room/${HashID.encode(roomCode)}`);
            } else {
              backToHome();
            }
          } else {
            /**
             * Otherwise, fallback to home page.
             * */
            backToHome();
          }
        }
      } catch (error) {
        console.log("Can't create room:", error);
        backToHome();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedCode]);

  const pushRoomChanges = useMemo(() => {
    return lodash.debounce((action: (() => void)) => {
      action();
    }, 800);
  }, []);

  /**
   * Listen local changes and update room.
   * */
  useEffect(() => {
    const roomUid = decodedCode;
    const roomData = room.data;

    if (
      taskUid &&
      task &&
      roomUid &&
      roomData &&
      auth.user &&
      (
        task.name !== taskName ||
        roomData.creatorParticipates !== taskParticipate ||
        roomData.pokerType !== taskPokerType
      )
    ) {
      pushRoomChanges(() => {
        updateRoom(
          roomUid,
          data => ({
            dirty: true,
            creatorParticipates: taskParticipate,
            pokerType: taskPokerType,
            tasks: {
              ...data.tasks,
              [taskUid]: {
                ...data.tasks[taskUid],
                name: taskName,
              },
            },
          })
        ).catch((error: Error) => {
          toast.show(t("app.TASK_UPDATE_ERROR"), "error");
          console.log(error);
        })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskName, taskParticipate, taskPokerType]);

  /**
   * Listen room changes and update state.
   * */
  useEffect(() => {
    const roomData = room.data;
    if (roomData) {
      console.log(" --- Restoring room changes");
      setTaskPokerTypeDirty(true);
      setTaskPokerType(roomData.pokerType);

      if (!isOwner || !taskParticipateDirty) {
        setTaskParticipateDirty(true);
        setTaskParticipate(roomData.creatorParticipates);
      }

      if (!isOwner || !taskNameDirty) {
        if (taskUid) {
          setTaskNameDirty(true);
          setTaskName(roomData.tasks[taskUid].name);
        }
      }

      if (taskNameDirty && auth.user && !roomData.participants[auth.user.uid] && !isOwner) {
        backToHome();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room.data?.pokerType,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room.data?.creatorParticipates,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room.data?.tasks[taskUid || ""]?.name,
    room.data?.tasks[taskUid ?? ""]?.votesFlipped,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    room.data?.participants[auth.user?.uid as string],
    isOwner
  ]);

  useEffect(() => {
    const hasAllVotes = Object.values(participants ?? []).every(p => !!(task?.votes || {})[p]);
    if (room.data?.tasks[taskUid ?? ""]?.votesFlipped || hasAllVotes) {
      const votes = Object.values(room.data?.tasks[taskUid ?? ""].votes ?? {});
      const areVotesEquals = votes.length > 0 && votes.every(v => v === votes[0]);

      console.log("Room change, votes=", votes, "equal=", areVotesEquals);

      if (areVotesEquals) {
        setTada(true);
      }
    }
  }, [room.data?.tasks[taskUid || ""]?.votesFlipped, room.data?.participants]);

  return (
    <Box className={classNames(styles.root, styles.column)}>
      {tada && <Tada onFinish={() => setTada(false)}/>}
      <Container>
        <Box className={styles.column} p={1}>
          <Box className={styles.topRow}>
            <Box
              flex={1}
              className={styles.column}
              p={task?.name ? 1 : 0}>
              <Grow
                in={!room.loading}
                timeout={getTimeout(true)}>
                <Box p={1} className={styles.column} mb={4}>
                  <Paper>
                    <Box className={styles.column} p={1}>
                      <Box className={styles.columnOnMobile}>
                        <Box
                          flex={1}
                          className={styles.column}
                          p={1}>

                          {isOwner ? (
                            <TextField
                              size={"small"}
                              label={t(
                                "app.TASK_NAME"
                              )}
                              variant="outlined"
                              value={taskName}
                              onChange={e =>
                                setTaskName(
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Box p={task?.name ? 0 : 0}>
                              <Typography variant={"h5"} fontWeight={"600"}>
                                {task?.name ?? '-'}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        {isOwner && <>
                            <Box p={1}>
                                <FormControlLabel
                                    control={
                                      <Switch
                                        color={
                                          "primary"
                                        }
                                        checked={
                                          taskParticipate
                                        }
                                        onChange={e =>
                                          setTaskParticipate(
                                            e.target
                                              .checked
                                          )
                                        }
                                      />
                                    }
                                    label={t(
                                      "app.PARTICIPATE_IN_THE_VOTE"
                                    )}
                                />
                            </Box>
                        </>}
                      </Box>

                      <Box className={styles.columnOnMobile}>
                        <Box p={1} className={styles.columnOnMobile} sx={{flex: 1}}>
                          <Select
                            size={"small"}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={taskPokerType}
                            onChange={e => setTaskPokerType(e.target.value)}
                          >
                            {Object.entries(DEF_POKER_TYPES).map(([type, {title}], i) => (
                              <MenuItem value={type} key={i}>
                                {t(title)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>

                        <Box className={styles.box} sx={{justifyContent: "space-between"}}>
                          <Box className={styles.columnOnMobile} p={1} sx={{flex: 1}}>
                            <Button
                              onClick={flipVotes}
                              variant={"outlined"}>
                              {t("app.FLIP_CARDS")}
                            </Button>
                          </Box>
                          <Box className={styles.columnOnMobile} p={1} sx={{flex: 1}}>
                            <Button
                              sx={{whiteSpace: 'nowrap'}}
                              onClick={reset}
                              variant={"outlined"}>
                              {t("app.RESET_VOTING")}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Grow>

              <PokerCard room={room} task={task}
                         tada={tada}
                         taskUID={taskUid}
                         timeout={getTimeout()} loading={room.loading}
                         cards={DEF_POKER_TYPES[taskPokerType]?.cards || []}
                         participants={participants}/>
            </Box>

            <Box className={styles.participantsList} p={1}>
              <TeamCard
                loading={room.loading}
                timeout={getTimeout()}
                participants={participants}
                creatorUID={room.data?.creatorUid}
                votes={task?.votes || {}}
                votesFlipped={!!task?.votesFlipped}
                onRemoved={remove}
              />
            </Box>
          </Box>
        </Box>
      </Container>

      <Grow in={room.loading}>
        <Box
          className={styles.loading}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Typography>{t("app.CREATING_ROOM")}</Typography>

          <Box ml={2} lineHeight={0}>
            <CircularProgress color={"primary"} size={24}/>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
};
