import React, {useEffect, useMemo} from "react";
import {getApps, initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInAnonymously, signInWithRedirect} from "firebase/auth";
import "firebase/installations";
import {doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import {child, getDatabase, onDisconnect, ref, set} from "firebase/database";
import {observer, useLocalObservable} from "mobx-react";
import {AuthContextValue, Session} from "./types";
import {useToast} from "../components/Toast/hooks";
import {useTranslation} from "react-i18next";
import {Box, Button, CircularProgress, makeStyles, Paper, TextField, Typography,} from "@material-ui/core";
import {useQueryParam} from "use-query-params";
import {toJS} from "mobx";

if (getApps().length === 0) {
  initializeApp({
    apiKey: "AIzaSyCC-oHCyXSTYQYQHIntbAue5DO3SAnk8DM",
    authDomain: "taskplanning.app",
    // authDomain: "task-planning-app.firebaseapp.com",
    projectId: "task-planning-app",
    storageBucket: "task-planning-app.appspot.com",
    messagingSenderId: "941240298145",
    appId: "1:941240298145:web:56684479071266bc3bdee0",
    measurementId: "G-3ZHWV72J9N",
  });
}

const Auth = getAuth();
const Firestore = getFirestore();
const Database = getDatabase();

export const AuthContext = React.createContext<AuthContextValue | null>(null);

const useStyles = makeStyles({
  loading: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.48,
  },
  authRequest: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  authRequestCard: {
    width: 360,
  },
  name: {
    textAlign: "center",
  },
});

export const AuthProvider: React.FC = observer(({children}) => {
  const [auth] = useQueryParam("auth");
  const {t} = useTranslation();
  const toast = useToast();
  const styles = useStyles();

  const inputRef = useMemo<{ current: HTMLInputElement | null }>(
    () => ({
      current: null,
    }),
    []
  );

  /**
   * Creating store.
   * */
  const value = useLocalObservable<AuthContextValue>(() => {
    return {
      async signIn() {
        this.loading = true;

        const currentUser = Auth.currentUser;
        if (currentUser && currentUser.isAnonymous) {
          alert("Upgrading anon");
          await currentUser.delete();
        }

        await signInWithRedirect(Auth, new GoogleAuthProvider());
      },

      async signOut() {
        await Auth.signOut();
      },

      async signAnonymously() {
        this.loading = true;
        await signInAnonymously(Auth);
      },

      user: null,
      setUser(user) {
        this.user = user;
      },

      loading: true,
      setLoading(loading: boolean) {
        this.loading = loading;
      },
    };
  });

  /**
   * Watcher for auth changes.
   * */
  useEffect(
    () =>
      Auth.onAuthStateChanged(async user => {
        console.log(` --- Auth status changed. User=${user?.uid}`);

        if (user) {
          const profileResponse = await getDoc(doc(Firestore, "users", user.uid));
          const profile: any = profileResponse.data() || {};
          const providerData = user.providerData[0] ?? null;

          console.log(user);

          value.setUser({
            uid: user.uid,
            name:
              providerData?.displayName ||
              user.displayName ||
              profile.name ||
              inputRef.current?.value ||
              t("auth.SIGN_IN_ANON_USER"),
            photo: providerData?.photoURL ?? user.photoURL,
            anonymous: user.isAnonymous,
          });

          console.log(" --- Updating user profile and session...");
          console.log(value.user?.photo);

          await setDoc(doc(Firestore, "users", user.uid), toJS(value.user) || {});

          const sessionRef = child(ref(Database, "sessions"), user.uid);
          set(sessionRef, {
            userUid: user.uid,
            start: new Date().toISOString(),
            startT: +new Date(),
            startTR: -new Date(),
          } as Session)
            .then(() => {
              toast.show(
                t("auth.SIGN_IN_SUCCESS", {
                  name: value.user?.name || "-",
                })
              );
            })
            .catch(() => {
              toast.show(t("auth.SIGN_IN_ERROR"));
            });
          onDisconnect(sessionRef).remove().then(null);
        } else {
          value.setUser(null);
        }

        value.setLoading(false);

        if (inputRef.current) inputRef.current.value = "";
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, toast, value]
  );

  const requestAuth = useMemo(() => {
    return (auth === "request-sign-in" || window.location.href.includes("/room/")) && !value.user;
  }, [auth, value.user]);

  return (
    <AuthContext.Provider value={value}>
      {value.loading ? (
        <Box className={styles.loading}>
          <CircularProgress size={"36px"} color={"primary"}/>
        </Box>
      ) : requestAuth ? (
        <Box className={styles.authRequest}>
          <Paper className={styles.authRequestCard}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"stretch"}
              p={1}>
              <Box p={1} display={"flex"} alignItems={"center"}>
                <TextField
                  inputRef={ref =>
                    ref && (inputRef.current = ref)
                  }
                  variant={"outlined"}
                  inputProps={{
                    className: styles.name,
                    placeholder: t("auth.SIGN_IN_NAME"),
                  }}
                />

                <Box ml={2}>
                  <Button
                    onClick={value.signAnonymously}
                    size={"large"}
                    variant={"contained"}>
                    {t("auth.SIGN_IN_JOIN")}
                  </Button>
                </Box>
              </Box>

              <Box
                p={1}
                display={"flex"}
                justifyContent={"center"}>
                <Typography variant={"h6"} textAlign={"center"}>
                  {t("auth.SIGN_IN_OR")}
                </Typography>
              </Box>

              <Box
                p={1}
                display={"flex"}
                justifyContent={"center"}>
                <Button
                  variant={"outlined"}
                  size={"large"}
                  onClick={value.signIn}>
                  {t("auth.SIGN_IN_GOOGLE")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
});
