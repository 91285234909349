import React from "react";
import { RoomParticipantProps } from "./types";
import {
    Box,
    Chip,
    Grow,
    IconButton,
    makeStyles,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useParticipant } from "../../database/hooks";
import { Coffee, DeleteForever, Person } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAuth } from "../../auth/hooks";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles<DefaultTheme, { index: number }>(theme => ({
    offline: {
        opacity: 0.4,
    },
    avatar: {
        width: 36,
        height: 36,
        borderRadius: 18,
        overflow: "hidden",
        display: "block"
    },
    voteBox: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        lineHeight: 0,
        width: 0,
        height: 0,
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main + "15",
        transition: "all 280ms cubic-bezier(0.75, 0.35, 0.35, 0.75)",
        overflow: "hidden",
    },
    voteBoxActive: {
        width: 32,
        height: 42,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    voteBoxInner: {
        position: "absolute",
        lineHeight: 0,
        width: 32 * 0.6,
        height: 42 * 0.6,
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main + "10",
    },
    voteBoxTurned: ({ index }) => ({
        animation: `$turnSmallCardOn 280ms cubic-bezier(0.75, 0.35, 0.35, 0.75) ${
            80 * index
        }ms`,
    }),
    "@keyframes turnSmallCardOn": {
        "0%": () => ({
            borderColor: "transparent",
            transform: "scale(1)",
        }),
        "50%": () => ({
            borderColor: "transparent",
            transform: "scale(0, 1)",
        }),
        "100%": () => ({
            borderColor: theme.palette.primary.main,
            transform: "scale(1)",
        }),
    },
}));

export const RoomParticipant: React.FC<RoomParticipantProps> = ({
    userUid,
    moderator,
    moderatorUid,
    vote,
    votesFlipped,
    showVote,
    index,
    onRemoved,
}) => {
    const { t } = useTranslation();
    const { data } = useParticipant(userUid);

    const styles = useStyles({index});
    const theme = useTheme();
    const auth = useAuth();

    const isCreator = moderatorUid === auth.user?.uid;

    return (
        <Grow in={!!data}>
            <Box pl={1} pr={1} display={"flex"} alignItems={"center"}>
                <Box
                    display={"flex"}
                    flex={1}
                    alignItems={"center"}
                    className={classNames({
                        [styles.offline]: data && !data.online,
                    })}>

                    <Box className={classNames(styles.voteBox, {[styles.voteBoxActive]: vote, [styles.voteBoxTurned]: showVote})}>
                        {!showVote && <Box className={styles.voteBoxInner}/>}

                        {!showVote ? "" :
                            vote === "C" ? (
                                <Coffee style={{ fontSize: 26, color: theme.palette.primary.main }} />
                            ) : vote === "0.5" ? (
                                <Typography
                                    fontSize={18}
                                    fontWeight={"700"}
                                    color={theme.palette.primary.main}
                                    variant={"body2"}>
                                    1/2
                                </Typography>
                            ) : (
                                <Typography
                                    fontSize={28}
                                    fontWeight={"700"}
                                    color={theme.palette.primary.main}
                                    variant={"body2"}>
                                    {vote}
                                </Typography>
                            )}
                    </Box>

                    <Box p={1} lineHeight={0}>
                        {data?.user?.photo ? (
                            <img
                                className={styles.avatar}
                                alt={data?.user?.name || "-"}
                                src={data?.user?.photo || ""}
                            />
                        ) : (
                            <Box className={styles.avatar}>
                                <Person fontSize={"large"} />
                            </Box>
                        )}
                    </Box>

                    <Box
                        p={1}
                        display={"flex"}
                        flex={1}
                        flexDirection={"column"}>
                        <Typography variant={"body2"} fontWeight={"600"}>
                            {data?.user?.name}
                        </Typography>

                        {!vote ? (
                            <Typography variant={"body2"} fontWeight={"300"}>
                                {votesFlipped ? t("app.NO_VOTE") : t("app.WAITING_VOTE")}
                            </Typography>
                        ) : (
                            <Typography variant={"body2"} fontWeight={"700"}
                                        color={theme.palette.primary.main}>
                                {t("app.VOTED")}
                            </Typography>
                        )}
                    </Box>
                </Box>

                {moderator && (
                    <Box p={1}>
                        <Chip
                            label={t("app.MODERATOR")}
                            title={t("app.MODERATOR_FULL")}
                            size={"small"}
                            color={"primary"}
                        />
                    </Box>
                )}

                {!!data && !data.online && (
                    <Box p={1}>
                        <Chip
                            label={t("app.OFFLINE")}
                            title={t("app.OFFLINE_FULL")}
                            size={"small"}
                            color={"default"}
                        />
                    </Box>
                )}

                {!!onRemoved && isCreator && (
                    <Box p={1}>
                        <IconButton onClick={() => onRemoved(userUid)}>
                            <DeleteForever />
                        </IconButton>
                    </Box>
                )}

            </Box>
        </Grow>
    );
};
