const HASH_KEY = { p: 3, t: 7685, s: 7685 };

export const HashID = {
  encode,
  decode
}

function encode(id: string): string {
  return btoa(String(applyKey(parseInt(id))));
}

function decode(id: string): string | null {
  try {
    return String(unapplyKey(parseInt(atob(id))));
  } catch(error) {
    console.log(`Can't decode "${id}". Error:`, error.message);
  }
  return null;
}

function applyKey(id: number) {
  return Math.pow(id, HASH_KEY.p) * HASH_KEY.t + HASH_KEY.s;
}

function unapplyKey(id: number) {
  return Math.round(Math.pow((id - HASH_KEY.s) / HASH_KEY.t, 1.0 / HASH_KEY.p));
}
