import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, {}>(theme => ({
    "@keyframes turnCardOn": {
        "0%": {
            borderColor: "transparent",
            transform: "scale(1, 1)",
        },
        "50%": {
            borderColor: "transparent",
            transform: "scale(0, 1)",
        },
        "100%": {
            borderColor: theme.palette.primary.main,
            transform: "scale(1.08, 1.08)",
        },
    },
    root: {
        minHeight: 480,
    },
    loading: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
    },
    box: {
        position: "relative",
        display: "flex",
    },
    centralized: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    column: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    columnOnMobile: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    topRow: {
        position: "relative",
        display: "flex",
        alignItems: "stretch",

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    participantsList: {
        position: "relative",
        display: "flex",
        alignItems: "stretch",
        [theme.breakpoints.up("md")]: {
            minHeight: window.innerHeight * 0.88,
            width: 480,
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        },
    },
    participantsListPaper: {
        top: theme.spacing(1),
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            position: "absolute",
        }
    },
    participantsListScroll: {
        [theme.breakpoints.up("md")]: {
            overflowY: "auto",
            flex: "1",
        }
    },
    pokerCards: {
        display: "flex",
        flexWrap: "wrap",
        paddingBottom: theme.spacing(4),
        alignItems: "center",
        justifyContent: "center",
    },
    pokerCardWrapper: {
        margin: theme.spacing(1),
        backgroundColor: "white",
        borderRadius: 12,
        overflow: "hidden",
        boxShadow: theme.shadows[1],
        color: theme.palette.primary.main,
        borderWidth: 4,
        borderStyle: "solid",

        "&:hover": {
            boxShadow: theme.shadows[24],
            zIndex: 9999,
        },
    },
    pokerCardWrapperNotSelected: {
        borderColor: "transparent",
        transition: "all 280ms cubic-bezier(0.75, 0.35, 0.35, 0.75)",
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
    pokerCardWrapperSelected: {
        borderColor: theme.palette.primary.main,
        boxShadow: theme.shadows[10],
        animation: "$turnCardOn 280ms cubic-bezier(0.75, 0.35, 0.35, 0.75)",
        transform: "scale(1.08)",
    },
    pokerCard: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1),
        cursor: "pointer",
        width: 128,
        height: 192,
    },
    fitLoading: {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
    },
    percent: {
        position: "absolute",
        bottom: theme.spacing(2),
    },
}));
