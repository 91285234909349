import {collection, doc, getDocs, getFirestore, query, runTransaction, where} from "firebase/firestore";
import * as UUID from "uuid";
import {PokerType, Room as IRoom} from "../../database/types";
import {User} from "../../auth/types";

const Firestore = getFirestore();

export const createRoom = async (user: User): Promise<string | undefined> => {
  const existing = await getDocs(query(
    collection(Firestore, "rooms"),
    where("creatorUid", "==", user.uid),
    where("dirty", "==", false)
  ));

  if (!existing.empty) {
    console.log(` --- Existing room found.`);
    return existing.docs[0].data().code;
  } else {
    console.log(` --- Creating room for current user: ${user.name}.`);

    const serialRef = doc(Firestore, "rooms-serial", "default");
    let roomCode: string | undefined = undefined;

    return await new Promise((resolve, reject) => {
      runTransaction(Firestore, t => {
        return t.get(serialRef).then(serial => {
          let data = serial.data();
          let value = data ? data.value + 1 : 0;

          roomCode = String(value);

          t.set(serialRef, {value});
          t.set(doc(Firestore, "rooms", roomCode), {
            code: roomCode,
            creatorUid: user.uid,
            creatorParticipates: true,
            participants: {},
            pokerType: PokerType.FIBONACCI_LONG,
            tasks: {
              [UUID.v4()]: {
                name: "",
                votes: {},
                votesFlipped: false,
                chosenPoints: "UNKNOWN",
                mediationStart: new Date().toISOString(),
                mediationStartT: +new Date(),
                mediationStartTR: +new Date(),
              },
            },
            dirty: false,
          } as IRoom);
        });
      })
        .then(() => {
          resolve(roomCode);
        })
        .catch(reject);
    });
  }
};


export const updateRoom = async (code: string, next: (room: IRoom) => Partial<IRoom>): Promise<void> => {
  const ref = doc(Firestore, "rooms", code);
  await runTransaction(Firestore, (t) => {
    return t.get(ref).then(current => {
      t.update(ref, {...next(current.data() as IRoom)});
    });
  });
}
